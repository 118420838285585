import React, { PropsWithChildren, UIEvent } from 'react'
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Popper,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { Close } from '@mui/icons-material'

interface CustomPopperProps extends PropsWithChildren {
  label: string
  open: boolean
  anchorEl: HTMLDivElement
  resetDisabled: boolean
  handleReset: () => void
  handleClose: () => void
  headerIcon?: React.ReactNode
}

const FilterWrapper = styled(Box)(({ theme }) => ({
  background: 'white',
  width: 386,
  boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.2)',
  display: 'inline-block',
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(1),
}))

const Content = styled(Stack)(({ theme }) => ({
  maxHeight: 670,
  padding: theme.spacing(0, 2, 0),
  overflowY: 'auto',
}))

const PopperHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isscrollable',
})<{ isscrollable: boolean }>(({ theme, isscrollable }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3),
  borderBottom: '1px solid',
  borderColor: isscrollable ? theme.palette.divider : 'transparent',
  transition: 'all 0.2s ease',
}))

const PopperFooter = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isscrollable',
})<{ isscrollable: boolean }>(({ theme, isscrollable }) => ({
  padding: theme.spacing(1),
  borderTop: '1px solid',
  direction: 'rtl',
  borderColor: isscrollable ? theme.palette.divider : 'transparent',
  transition: 'all 0.2s ease',
}))

export const CustomPopper = ({
  label,
  open,
  anchorEl,
  resetDisabled,
  handleReset,
  handleClose,
  headerIcon = <Close />,
  children,
}: CustomPopperProps) => {
  const [isScrollable, setIsScrollable] = React.useState(false)

  const theySeeMeScrollin = (event: UIEvent<HTMLDivElement>) => {
    setIsScrollable((event.target as HTMLDivElement)?.scrollTop !== 0)
  }

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      placement="bottom-start"
      modifiers={[
        {
          name: 'flip',
          enabled: false,
        },
      ]}
      sx={{
        zIndex: 3,
        maxWidth: 352,
        width: '100%',
      }}
      translate="no"
    >
      <ClickAwayListener onClickAway={handleClose} touchEvent="onTouchStart">
        <FilterWrapper>
          <PopperHeader isscrollable={isScrollable}>
            <Typography variant="body1" fontWeight="bold">
              {label}
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{ position: 'absolute', right: 15 }}
            >
              {headerIcon}
            </IconButton>
          </PopperHeader>
          <Content onScroll={theySeeMeScrollin}>{children}</Content>
          <PopperFooter isscrollable={isScrollable}>
            <Button
              variant="text"
              onClick={handleReset}
              disabled={resetDisabled}
            >
              Rensa
            </Button>
          </PopperFooter>
        </FilterWrapper>
      </ClickAwayListener>
    </Popper>
  )
}
