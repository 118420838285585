import React from 'react'
import { ISchedule } from '@local/Activity/services/activity.types'
import {
  getRadioButtonDate,
  getRadioButtonAdress,
  getAddress,
} from '@local/Utils/Helpers/activity.helpers'
import { Link, ListItemText } from '@mui/material'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'
import { FmdGood, Monitor } from '@mui/icons-material'

interface CallToActionNoOccasionsProps {
  isOnline: boolean
  schedule: ISchedule
}

export const CallToActionNoOccasions: React.FC<
  CallToActionNoOccasionsProps
> = ({ isOnline, schedule }) => {
  const primaryText = getRadioButtonDate({
    start: schedule.parts[0].start,
    end: schedule.parts[0].end,
  })
  const secondaryText = getRadioButtonAdress(schedule.parts[0].physicalAddress)
  const splitSecondaryText = secondaryText.split('\n')

  return (
    <CardListItem>
      <CardListItemIcon alignTop>
        {isOnline ? <Monitor /> : <FmdGood />}
      </CardListItemIcon>
      <ListItemText
        primary={primaryText}
        secondary={
          isOnline ? (
            <>
              {splitSecondaryText[0]}
              {splitSecondaryText.length > 1 && (
                <>
                  <br />
                  {splitSecondaryText[1]}
                </>
              )}
            </>
          ) : (
            <Link
              href={`https://maps.google.com/?q=${getAddress(
                schedule.parts[0].physicalAddress
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              {splitSecondaryText[0]}
              {splitSecondaryText.length > 1 && (
                <>
                  <br />
                  {splitSecondaryText[1]}
                </>
              )}
            </Link>
          )
        }
      />
    </CardListItem>
  )
}
