import React from 'react'
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings'
import { getRoles } from '@local/Utils/Network'
import { Link, Typography } from '@mui/material'

const DinaAktiviteterLink: React.FunctionComponent = () => {
  const t = useEpiString()

  const uppsagdRoles = [
    'TIOAktiv',
    'TIOAvslutad',
    'Klient',
    'AvslutadKlient',
    'PausadKlient',
  ]

  const validRoles = [...uppsagdRoles, 'KundOmfattas', 'KundOmfattasEj']

  const shouldSeeLink = getRoles()?.reduce(
    (currentValue, role) => validRoles.includes(role) || currentValue,
    false
  )

  const isUppsagdRole = getRoles()?.reduce(
    (currentValue, role) => uppsagdRoles.includes(role) || currentValue,
    false
  )

  if (!shouldSeeLink) {
    return null
  }

  return (
    <Typography variant="body1" marginBottom={5}>
      {t('activityListLinkIngress')}
      <Link
        title={t('dinaAktiviteter.title')}
        href={
          isUppsagdRole
            ? t('dinaAktiviteter.klient.link')
            : t('dinaAktiviteter.customer.link')
        }
      >
        {t('dinaAktiviteter.label')}
      </Link>
    </Typography>
  )
}

export default DinaAktiviteterLink
