import React from 'react'
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@mui/material'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { PuffCard } from '@local/ActivitiesList/Components/PuffCards/Components/PuffCard'
import getConfig from '@local/Utils/ConfigService/getConfig'

export const TrrActivityPuff = () => {
  const {
    trrActivityPuffCard: { heading, body, actionText, actionUrl, imageUrl },
  } = usePickEpiContent()

  return (
    <PuffCard>
      {imageUrl && (
        <CardMedia
          component="img"
          alt={heading}
          height="140"
          image={getConfig().MEDIA_URL + imageUrl}
        />
      )}
      <CardHeader title={heading} />
      <CardContent sx={{ flex: 1 }}>
        <Typography variant="body1">{body}</Typography>
      </CardContent>
      {actionText && actionUrl && (
        <CardActions>
          <Button size="small" variant="text" href={actionUrl} role="button">
            {actionText}
          </Button>
        </CardActions>
      )}
    </PuffCard>
  )
}
