import React, { useEffect } from 'react'
import { isEmpty } from 'ramda'
import { useHistory } from 'react-router-dom'
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings'
import { useGetRegisteredActivitiesQuery } from '@local/UserActivities/services/userActivities.service'
import RegisteredActivityCard from '@local/UserActivities/RegisteredActivities/RegisteredActivityCard'
import { roles } from '@local/Utils/Network'
import { stringByRole } from '@local/Utils/Helpers/stringByRole'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import {
  Grid,
  Link,
  Button,
  CircularProgress,
  Typography,
  styled,
} from '@mui/material'
import { ActivityGrid } from '@local/Components/ActivityGrid'
import EmptyResult from '@local/Components/EmptyResult/EmptyResult'

const EmptyWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  button: {
    alignSelf: 'center',
    marginTop: '3rem',
  },
})

const RegisteredActivitiesList = (): JSX.Element => {
  const history = useHistory()
  const t = useEpiString()
  const { isCustomer } = useUserContext()

  const gotoActivities = React.useCallback(() => {
    const path = isCustomer
      ? t('findActivities.customer')
      : t('findActivities.klient')
    history.push(path)
  }, [history, isCustomer, t])

  const {
    isLoading,
    data: registeredActivities,
    error,
    refetch,
  } = useGetRegisteredActivitiesQuery()

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    (isEmpty(registeredActivities) && !isLoading) ||
    (!registeredActivities?.length && !isLoading) ||
    error
  ) {
    return (
      <EmptyWrapper>
        <EmptyResult
          description={
            roles?.includes('AvslutadKlient') ? '' : t('emptyListBody')
          }
          heading={t('emptyListTitle')}
        />
        {!roles?.includes('AvslutadKlient') && (
          <Button onClick={gotoActivities}>{t('findNewActivityText')}</Button>
        )}
      </EmptyWrapper>
    )
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <div>
      <Typography variant="body1" mb={3}>
        {t('findActivitiesLinkText')}
        <Link
          title="Länk till hitta aktiviteter"
          href={stringByRole({
            clientString: t('findActivities.klient'),
            customerString: t('findActivities.customer'),
          })}
        >
          {t('findActivitiesLinkLabel')}
        </Link>
      </Typography>
      <ActivityGrid data-testid="bokat-tillfalle">
        {registeredActivities?.map((activity) => (
          <Grid item xs={1} sm={1} md={1} key={activity.id}>
            <RegisteredActivityCard {...activity} />
          </Grid>
        ))}
      </ActivityGrid>
    </div>
  )
}

export default RegisteredActivitiesList
