import { CustomPopper } from '@local/Components/CustomPopper'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Avatar, Chip, styled } from '@mui/material'
import React, { PropsWithChildren, useEffect, useRef } from 'react'

interface FilterDropdownProps {
  label: string
  selectCount: number
  popperTestId?: string
  chipTestId?: string
  handleReset: () => void
}

const ChipLabelAndIcon = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: -7,
}))

export const ChipPopover = ({
  label,
  selectCount,
  children,
  chipTestId,
  handleReset,
}: FilterDropdownProps & PropsWithChildren) => {
  const chipRef = useRef<HTMLDivElement>()
  const [open, setOpen] = React.useState(false)

  const hasSelectedFilters = selectCount > 0
  const SelectCountIndicator = (
    <Avatar sx={{ bgcolor: '#089188', color: '#fff !important' }}>
      {selectCount}
    </Avatar>
  )

  useEffect(() => {
    if (open) {
      const chipBottom = chipRef.current?.offsetTop
      const popperHeight = 500
      const viewportBottom = window.scrollY + window.innerHeight
      const shouldScrollIntoView = chipBottom + popperHeight > viewportBottom
      shouldScrollIntoView &&
        chipRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [open])

  return (
    <>
      <Chip
        ref={chipRef}
        avatar={hasSelectedFilters ? SelectCountIndicator : undefined}
        label={
          <ChipLabelAndIcon>
            {label} {open ? <ArrowDropUp /> : <ArrowDropDown />}
          </ChipLabelAndIcon>
        }
        onClick={() => setOpen(!open)}
        data-testid={chipTestId}
        variant={open || hasSelectedFilters ? 'filled' : 'outlined'}
      />
      <CustomPopper
        label={label}
        open={open}
        anchorEl={chipRef.current}
        resetDisabled={!hasSelectedFilters}
        handleReset={handleReset}
        handleClose={() => setOpen(false)}
      >
        {children}
      </CustomPopper>
    </>
  )
}
