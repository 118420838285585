import React from 'react'
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings'
import { trimTrailingSlash } from '@local/Utils/Helpers/stringHelper'
import { IAttendedActivity } from '@local/UserActivities/services/userActivities.types'
import { datespan } from '@trr/frontend-datetime'
import { TabName } from '@local/Activity/Components/ActivityTablist/ActivityTabList'
import { FromSourceTypes } from '@local/Types'
import { Tags } from '@local/Medarbetare/Services/medarbetareActivity.types'
import { getTagText } from '@local/Utils/Helpers/tagHelper'
import { stringByRole } from '@local/Utils/Helpers/stringByRole'
import { CardHeader, Chip, List, ListItemText } from '@mui/material'
import { FmdGood, InsertInvitation, MonitorOutlined } from '@mui/icons-material'
import { ActivityCardWrapper } from '@local/Components/ActivityCardWrapper'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'
import { ActivityFormIconCardListItem } from '@local/Components/ActivityFormIconCardListItem'
import {
  UserActivityCardChipContent,
  UserActivityCardPrimaryContent,
} from '@local/UserActivities/Components/UserActivityCardParts'
import { useFeatureFlag } from '@local/Utils/Hooks/useFeatureFlag/useFeatureFlag'
import { useTrackCustomClick } from '@trr/gtm-tracking'

const AttendedActivityCard: React.FunctionComponent<IAttendedActivity> = ({
  id,
  activity: { name, form },
  targetAudience: { locations },
  schedule: { start, end },
  workMaterial: { hasMaterial },
  isOnline,
  tags,
}) => {
  const t = useEpiString()
  const isActivityformEnabled = useFeatureFlag()(
    'aktivitetsbokningar.showAktivitetsformFilter'
  )
  const shouldTrack = useFeatureFlag()('gtm.enabled.aktivitetsbokningar')
  const trackClick = useTrackCustomClick()

  const baseActivityUrl = stringByRole({
    clientString: t('activityPage.klient'),
    customerString: t('activityPage.customer'),
  })

  const baseUrl = `${trimTrailingSlash(baseActivityUrl)}`
  const activityCardUrl = `${baseUrl}/${id}?fromSource=${FromSourceTypes.Attended}#${TabName.Start}`

  const handleActivityClick = () => {
    if (shouldTrack) {
      trackClick('aktivitetskort', {
        label: 'Gå till genomförd aktivitet',
        href: baseUrl,
      })
    }
  }

  return (
    <ActivityCardWrapper
      key={id}
      size="small"
      href={!tags?.includes(Tags.CANCELLED) ? activityCardUrl : null}
      onClick={handleActivityClick}
    >
      <CardHeader title={name} />
      <UserActivityCardPrimaryContent>
        <List>
          <CardListItem divider={false}>
            <CardListItemIcon aria-hidden>
              <InsertInvitation />
            </CardListItemIcon>
            <ListItemText
              data-testid="attendedActivityDate"
              primary={datespan.format(start, end)}
            />
          </CardListItem>
          <CardListItem divider={false}>
            <CardListItemIcon aria-hidden>
              {isOnline ? <MonitorOutlined /> : <FmdGood />}
            </CardListItemIcon>
            <ListItemText
              data-testid="attendedActivityDate"
              primary={isOnline ? 'Online' : locations[0]}
            />
          </CardListItem>
          {isActivityformEnabled && form && (
            <ActivityFormIconCardListItem form={form} />
          )}
        </List>
      </UserActivityCardPrimaryContent>
      <UserActivityCardChipContent>
        {hasMaterial && (
          <Chip label={t('hasMaterialsChip')} sx={{ mt: 0 }} color="success" />
        )}
        {tags?.map((tag) => (
          <Chip
            label={getTagText(tag)}
            key={tag}
            sx={{ mt: 0 }}
            color="error"
          />
        ))}
      </UserActivityCardChipContent>
    </ActivityCardWrapper>
  )
}

export default AttendedActivityCard
