import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { useGetActivityQuery } from '@local/Activity/services/activity.service'
import {
  connectActivityRoundUpdate,
  disconnectActivityRoundUpdate,
} from '@local/Utils/Helpers/signalRHelper'
import StartStep from '@local/Activity/Components/RegistrationSteps/StartStep'
import { ActionStep } from '@local/Activity/Components/RegistrationSteps/ActionStep'
import { CompletedStep } from '@local/Activity/Components/RegistrationSteps/CompletedStep'
import { TabName } from '@local/Activity/Components/ActivityTablist/ActivityTabList'
import { ActivityCallToAction } from '@local/Activity/Components/ActivityCallToAction'
import SignupProgression from '@local/Activity/Components/SignupProgression/SignupProgression'
import { FailedStep } from '@local/Activity/Components/RegistrationSteps/FailedStep'
import { ISignalRResponse } from '@local/Activity/services/signup.types'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import { useLazySignToActivityQuery } from '@local/Activity/services/singup.service'
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings'
import { Pages } from '@local/App'
import { REGISTRATION_AVAILABILITY } from '@local/Activity/ActivityStatuses'
import { CircularProgress, Link } from '@mui/material'
import {
  signupSteps,
  useSearchQueryParams,
} from '@local/Utils/Hooks/useSearchQueryParams'
import EmptyResult from '@local/Components/EmptyResult/EmptyResult'

interface ActivityProps {
  connection?: HubConnection
}

export const Activity = ({ connection }: ActivityProps) => {
  const t = useEpiString()
  const history = useHistory()
  const { hash, pathname, search } = useLocation()
  const { isCustomer } = useUserContext()
  const {
    searchParams: { id, step },
    setSearchParamField,
  } = useSearchQueryParams()
  const stickyRef = useRef(null)
  const {
    data: activity,
    isLoading,
    error,
    refetch,
  } = useGetActivityQuery(
    {
      id,
      isCustomer,
    },
    { skip: id === '' }
  )

  const [currentTab, setCurrentTab] = useState<TabName>(TabName.Start)
  const [signalRResponse, setSignalRResponse] = useState<ISignalRResponse>(null)
  const [triggerSign, lazySignResponse] = useLazySignToActivityQuery()
  const [lastSignId, setLastSignId] = useState<string>(null)
  const [lastSignalRId, setLastSignalRId] = useState<string>(id)
  const [isReadyForSignups, setIsReadyForSignups] = useState<boolean>(false)
  const {
    isError: isSignError,
    isLoading: isSignLoading,
    isSuccess: isSignSuccess,
    data: signData,
  } = lazySignResponse
  const shouldShowSignupLoading =
    step === signupSteps.Completed && isSignSuccess && !signalRResponse

  const shouldShowSignupCompleted =
    step === signupSteps.Completed && signalRResponse && signalRResponse.Success

  const shouldShowSignupFailed =
    (step === signupSteps.Completed &&
      signalRResponse &&
      !signalRResponse.Success) ||
    (step === signupSteps.Completed && isSignError)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    setIsReadyForSignups(false)
    const signalRInterval = setInterval(() => {
      if (connection?.state === HubConnectionState.Connected) {
        clearInterval(signalRInterval)
        setIsReadyForSignups(true)
        disconnectActivityRoundUpdate(connection, lastSignalRId)
        connectActivityRoundUpdate(connection, id, refetch)
      }
      setLastSignalRId(id)
    }, 1000)
    return () => {
      clearInterval(signalRInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (history.action === 'POP' && step === signupSteps.Completed) {
      setSearchParamField(signupSteps.Start, 'step')
    }
  })

  useEffect(() => {
    if (lazySignResponse.requestId !== lastSignId) {
      if (isSignError || isSignSuccess || isSignLoading) {
        setSignalRResponse(null)
        setLastSignId(lazySignResponse.requestId)
        setSearchParamField(signupSteps.Completed, 'step')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazySignResponse])

  useEffect(() => {
    switch (hash) {
      case `#${TabName.Start}`:
        break
      case `#${TabName.Preparations}`:
        setCurrentTab(TabName.Preparations)
        break
      case `#${TabName.Materials}`:
        setCurrentTab(TabName.Materials)
        break
      default:
        handleSetTab(TabName.Start)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSetTab = (tab: TabName) => {
    setCurrentTab(tab)
    history.replace(`${pathname}${search}#${tab}`)
  }

  if (isLoading) {
    return <CircularProgress />
  }

  if (
    (error && !shouldShowSignupCompleted) ||
    activity?.registration?.registrationAvailability ===
      REGISTRATION_AVAILABILITY.LAST_REGISTRATION_DATE_PASSED
  ) {
    return (
      <>
        <EmptyResult
          heading={t('errorPage.title')}
          description={t('errorPage.description')}
          action={{
            label: t('errorPage.button'),
            onClick: () =>
              history.push(
                pathname.substring(
                  0,
                  pathname.lastIndexOf(`/${Pages.Aktivitet}`)
                )
              ),
          }}
        />
        <Link href={t('errorPage.linkUrl')}>{t('errorPage.linkText')}</Link>
      </>
    )
  }

  return (
    <>
      {(step === '' || step === signupSteps.Start) && (
        <StartStep
          currentTab={currentTab}
          stickyRef={stickyRef}
          onSetTab={handleSetTab}
          activity={activity}
        >
          <ActivityCallToAction activity={activity} />
        </StartStep>
      )}
      {step === signupSteps.Action && (
        <ActionStep
          triggerSign={triggerSign}
          isReadyForSignups={isReadyForSignups}
        />
      )}
      {shouldShowSignupLoading && (
        <SignupProgression
          connection={connection}
          setSignalRResponse={setSignalRResponse}
        />
      )}
      {shouldShowSignupCompleted && <CompletedStep />}
      {shouldShowSignupFailed && (
        <FailedStep
          failureReason={signData?.error ?? signalRResponse?.Message}
        />
      )}
    </>
  )
}
