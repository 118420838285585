import React, { useEffect, useRef } from 'react'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import VideoList from '@local/ActivitiesList/Components/VideoList/VideoList'
import { Box } from '@mui/material'
import { Filters } from '@local/ActivitiesList/Components/Filtering/Filters'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'

export const WebinarsList = () => {
  const wrapperRef = useRef(null)
  const [initialyLoaded, setInitialyLoaded] = React.useState(false)
  const { isCustomer } = useUserContext()

  const {
    searchParams: { page },
  } = useSearchQueryParams()

  useEffect(() => {
    initialyLoaded
      ? wrapperRef?.current?.scrollIntoView({ behavior: 'smooth' })
      : setInitialyLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <Box mb={6} ref={wrapperRef}>
      <Filters showRecordedAreasOfInterestFilter={!isCustomer} />
      <VideoList isCustomer={isCustomer} />
    </Box>
  )
}
