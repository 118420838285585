import React from 'react'
import { FilterListItem } from '@local/ActivitiesList/Components/Filtering/Components/FilterListItem'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { useGetWebinarCategoriesQuery } from '@local/WebinarsList/services/webinar.service'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import { List } from '@mui/material'
import { useFeatureFlag } from '@local/Utils/Hooks/useFeatureFlag/useFeatureFlag'
import { useTrackCustomClick } from '@trr/gtm-tracking'

export const RecordedAreasOfInterestFilterList = () => {
  const {
    searchParams: { areasOfInterest: pickedAreasOfInterest },
    setSearchParamField,
  } = useSearchQueryParams()
  const { isCustomer } = useUserContext()
  const { data: areasOfInterest } = useGetWebinarCategoriesQuery({
    isClient: !isCustomer,
  })
  const shouldTrack = useFeatureFlag()('gtm.enabled.aktivitetsbokningar')
  const trackClick = useTrackCustomClick()

  const handleSetCategory = (category: string) => {
    if (shouldTrack && !pickedAreasOfInterest?.includes(category)) {
      trackClick('Filtrering', { label: `Kategori: ${category}` })
    }
    setSearchParamField(category, 'areasOfInterest', true)
  }

  return (
    <List data-testid="listFilterAreasOfInterestList" disablePadding>
      {areasOfInterest?.map((category) => (
        <FilterListItem
          title={category.label}
          key={category.label}
          amount={category.count}
          active={pickedAreasOfInterest.includes(category.label)}
          handleOnClick={() => handleSetCategory(category.label)}
          disabled={
            !pickedAreasOfInterest.includes(category.label as string) &&
            category.count === 0
          }
        />
      ))}
    </List>
  )
}
