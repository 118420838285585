import React from 'react'
import ActivityCard from '@local/ActivitiesList/Components/ActivityCard/ActivityCard'
import { IGetFilteredActivitiesResponse } from '@local/ActivitiesList/services/activitiesList.types'
import { IGetFilteredActivitiesMedarbetareResponse } from '@local/Medarbetare/Services/medarbetareActivitiesList.types'
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings'
import { ActivityGrid } from '@local/Components/ActivityGrid'
import { Box, CircularProgress, Grid, Pagination, Stack } from '@mui/material'
import {
  filterTabs,
  useSearchQueryParams,
} from '@local/Utils/Hooks/useSearchQueryParams'
import EmptyResult from '@local/Components/EmptyResult/EmptyResult'

interface ActivityCardListProps {
  activities:
    | IGetFilteredActivitiesMedarbetareResponse
    | IGetFilteredActivitiesResponse
  isLoading: boolean
}

const ActivityCardList: React.FunctionComponent<ActivityCardListProps> = ({
  activities,
  isLoading,
}) => {
  const t = useEpiString()
  const {
    searchParams: { tab },
    setSearchParamField,
  } = useSearchQueryParams()

  const handlePaginationChange = (newPage: number) => {
    setSearchParamField(newPage.toString(), 'page')
  }

  if (isLoading) {
    return <CircularProgress />
  }

  const shouldShowActivities =
    tab !== filterTabs.Recorded && activities?.items.length > 0

  if (!shouldShowActivities) {
    return (
      <Box
        sx={{
          width: '100%',
          paddingTop: '2rem',
          paddingBottom: '2rem',
        }}
      >
        <EmptyResult
          description={t('activityListEmptyResultDescription')}
          heading={t('activityListEmptyResultHeading')}
        />
      </Box>
    )
  }

  const shouldShowPagination =
    Boolean(activities?.items.length) && activities.totalPages > 1

  return (
    <>
      <ActivityGrid data-testid="activityCardWrapper">
        {shouldShowActivities &&
          activities.items.map((activity) => (
            <Grid item xs={1} sm={1} md={1} key={activity.nextActivityRoundId}>
              <ActivityCard {...activity} layout="standing" />
            </Grid>
          ))}
      </ActivityGrid>
      {shouldShowPagination && (
        <Stack alignItems={'end'}>
          <Pagination
            translate="no"
            onChange={(_, newPage) => handlePaginationChange(newPage)}
            count={activities?.totalPages}
            page={activities?.page}
            data-testid={'pagination'}
          />
        </Stack>
      )}
    </>
  )
}

export default ActivityCardList
