import React from 'react'
import { PuffCard } from '@local/ActivitiesList/Components/PuffCards/Components/PuffCard'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@mui/material'
import getConfig from '@local/Utils/ConfigService/getConfig'

export const RecordedPuff = () => {
  const {
    recordedPuffCard: { heading, body, actionText, actionUrl, imageUrl },
  } = usePickEpiContent()

  return (
    <PuffCard>
      {imageUrl && (
        <CardMedia
          component="img"
          alt={heading}
          height="140"
          image={getConfig().MEDIA_URL + imageUrl}
        />
      )}
      <CardHeader title={heading} />
      <CardContent sx={{ flex: 1 }}>
        <Typography variant="body1">{body}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant="text" href={`${actionUrl}`} role="button">
          {actionText}
        </Button>
      </CardActions>
    </PuffCard>
  )
}
