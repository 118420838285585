import React, { useEffect, useRef } from 'react'
import { useGetFilteredActivitiesQuery } from '@local/ActivitiesList/services'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import DinaAktiviteterLink from '@local/ActivitiesList/Components/DinaAktiviteterLink/DinaAktiviteterLink'
import ActivityCardList from '@local/ActivitiesList/Components/ActivityCardList/ActivityCardList'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { BonzunPuff } from '@local/ActivitiesList/Components/PuffCards/BonzunPuff/BonzunPuff'
import {
  PuffGridContainer,
  PuffGridItem,
} from '@local/ActivitiesList/Components/PuffGrid/PuffGrid'
import { Box } from '@mui/material'
import { useFeatureFlag } from '@local/Utils/Hooks/useFeatureFlag/useFeatureFlag'
import { EducationArticlePuff } from '@local/ActivitiesList/Components/PuffCards/EducationArticlePuff/EducationArticlePuff'
import { Filters } from '@local/ActivitiesList/Components/Filtering/Filters'
import { RecordedPuff } from '@local/ActivitiesList/Components/PuffCards/RecordedPuff/RecordedPuff'
import { TrrActivityPuff } from '@local/ActivitiesList/Components/PuffCards/TrrActivityPuff/TrrActivityPuff'

export const ActivitiesList: React.FunctionComponent = () => {
  const wrapperRef = useRef(null)
  const [initialyLoaded, setInitialyLoaded] = React.useState(false)
  const { isCustomer } = useUserContext()
  const showBonzunPuff = useFeatureFlag()('aktivitetsbokningar.showBonzunPuff')
  const showEducationArticlePuff = useFeatureFlag()(
    'aktivitetsbokningar.showEducationArticlePuff'
  )
  const isActivityformEnabled = useFeatureFlag()(
    'aktivitetsbokningar.showAktivitetsformFilter'
  )

  const showBonzun = showBonzunPuff && !isCustomer
  const showEducationArticle = showEducationArticlePuff && isCustomer
  const showActivity = !isCustomer

  const {
    searchParams: {
      areasOfInterest,
      cities,
      isOnline,
      targetGroups,
      activityForm,
      page,
    },
  } = useSearchQueryParams()

  const activitySearchParams = {
    areasOfInterest,
    cities,
    targetGroups,
    activityForm,
    isOnline: isOnline ? isOnline : undefined,
    page,
  }

  const { data: activities, isFetching } = useGetFilteredActivitiesQuery({
    searchParams: activitySearchParams,
    isCustomer,
  })

  useEffect(() => {
    initialyLoaded
      ? wrapperRef?.current?.scrollIntoView({ behavior: 'smooth' })
      : setInitialyLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <div ref={wrapperRef}>
      <DinaAktiviteterLink />
      <Box mb={6}>
        <Filters
          showLocationFilter
          showTargetGroupFilter={isCustomer}
          showAreasOfInterestFilter={!isCustomer}
          showActivityformFilter={isActivityformEnabled}
        />
        <ActivityCardList activities={activities} isLoading={isFetching} />
      </Box>
      <PuffGridContainer>
        {showEducationArticle && (
          <PuffGridItem>
            <EducationArticlePuff />
          </PuffGridItem>
        )}
        {showBonzun && (
          <PuffGridItem>
            <BonzunPuff />
          </PuffGridItem>
        )}
        <PuffGridItem>
          <RecordedPuff />
        </PuffGridItem>
        {showActivity && (
          <PuffGridItem>
            <TrrActivityPuff />
          </PuffGridItem>
        )}
      </PuffGridContainer>
    </div>
  )
}
