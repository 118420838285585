import { ISignalRResponse } from '@local/Activity/services/signup.types'
import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { Dispatch, SetStateAction } from 'react'

export enum signalRRooms {
  activityRoundUpdate = 'activityRoundUpdate',
  clientRegistrationFeedback = 'clientRegistrationFeedback',
}

export const connectActivityRoundUpdate = (
  connection: HubConnection,
  activityId: string,
  refetch: () => void
) => {
  const activityIdLowercase = activityId.toLowerCase()
  if (connection && connection.state === HubConnectionState.Connected) {
    connection
      .invoke('AddToGroup', activityIdLowercase)
      .then(() =>
        connection.on(
          signalRRooms.activityRoundUpdate,
          () => activityIdLowercase && refetch()
        )
      )
  }
}
export const disconnectActivityRoundUpdate = (
  connection: HubConnection,
  activityId: string
): void => {
  const activityIdLowercase = activityId.toLowerCase()

  if (connection && connection.state === HubConnectionState.Connected) {
    connection.off(signalRRooms.activityRoundUpdate)
    if (activityIdLowercase) {
      connection.invoke('RemoveFromGroup', activityIdLowercase)
    }
  }
}

export const connectClientRegistrationFeedback = (
  connection: HubConnection,
  activityId: string,
  userId: string,
  setSignalRResponse: Dispatch<SetStateAction<ISignalRResponse>>
) => {
  const activityIdLowercase = activityId.toLowerCase()

  if (connection && connection.state === HubConnectionState.Connected) {
    const finalId = `${activityIdLowercase}-${userId}`
    connection
      .invoke('AddToGroup', finalId)
      .then(() =>
        connection.on(
          signalRRooms.clientRegistrationFeedback,
          (message: ISignalRResponse) => finalId && setSignalRResponse(message)
        )
      )
  }
}

export const disconnectClientRegistrationFeedback = (
  connection: HubConnection,
  activityId: string,
  userId: string
): void => {
  const activityIdLowercase = activityId.toLowerCase()

  if (connection && connection.state === HubConnectionState.Connected) {
    const finalId = `${activityIdLowercase}-${userId}`
    connection.off(signalRRooms.clientRegistrationFeedback)
    if (finalId) {
      connection.invoke('RemoveFromGroup', finalId)
    }
  }
}
