import { PuffCard } from '@local/ActivitiesList/Components/PuffCards/Components/PuffCard'
import getConfig from '@local/Utils/ConfigService/getConfig'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@mui/material'
import React from 'react'

export const EducationArticlePuff = () => {
  const {
    educationArticleCardPuff: {
      heading,
      body,
      actionText,
      actionUrl,
      imageUrl,
    },
  } = usePickEpiContent()

  return (
    <PuffCard>
      {imageUrl && (
        <CardMedia
          component="img"
          alt={heading}
          height="140"
          image={getConfig().MEDIA_URL + imageUrl}
        />
      )}
      <CardHeader title={heading} />
      <CardContent>
        <Typography variant="body1">{body}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant="text" href={actionUrl} role="button">
          {actionText}
        </Button>
      </CardActions>
    </PuffCard>
  )
}
