import { View } from '@local/ActivitiesList/services/activitiesList.types'
import getConfig from '@local/Utils/ConfigService/getConfig'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react/'
import { getOIDCUser } from '@trr/app-shell-communication'

export const { API_URL, PUBLIC_WEB_API_URL } = getConfig()

const oidcData = getOIDCUser()
export const id_token = oidcData?.id_token
export const userId = oidcData?.profile?.sub
export const roles = oidcData?.profile?.roles ?? []

export const getRoles = () => roles

export const viewTypeParam = (isCustomer: boolean) =>
  `view=${isCustomer ? View.Kund : View.Anstalld}`

export const trrFetchBaseQuery = (isMedarbetare = false) =>
  fetchBaseQuery({
    baseUrl: isMedarbetare ? `${PUBLIC_WEB_API_URL}` : `${API_URL}`,
    prepareHeaders: (headers) => {
      id_token && headers.set('authorization', `Bearer ${id_token}`)
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })
