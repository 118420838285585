import React from 'react'
import { ActivityForm } from '@local/Activity/services/activity.types'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import EpiParser from '@local/Activity/Components/Layout/EpiParser'
import { convertSwedishCharacters } from '@local/Activity/Components/ActivityDescription/Utils/convertSwedishCharacters'

interface ActivityDescriptionProps {
  form: ActivityForm
}

export const ActivityDescription = ({ form }: ActivityDescriptionProps) => {
  const { activityBaseDescription } = usePickEpiContent()
  const formattedFormName = convertSwedishCharacters(form)

  if (activityBaseDescription?.[formattedFormName] === undefined) {
    return
  }

  return (
    <EpiParser body={activityBaseDescription[formattedFormName].mainBody} />
  )
}
