import React from 'react'
import { Grid, GridProps } from '@mui/material'

const PuffGridContainer = ({ children, ...props }: GridProps) => (
  <Grid
    container
    spacing={{ xs: 1, sm: 2, md: 3 }}
    columns={{ xs: 1, sm: 2, md: 3 }}
    {...props}
  >
    {children}
  </Grid>
)

const PuffGridItem = ({ children, ...props }: GridProps) => (
  <Grid item xs={1} sm={1} md={1} {...props} sx={{ display: 'flex' }}>
    {children}
  </Grid>
)

export { PuffGridContainer, PuffGridItem }
