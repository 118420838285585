import React from 'react'
import { FilterListItem } from '@local/ActivitiesList/Components/Filtering/Components/FilterListItem'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import { useGetFilteredActivitiesQuery } from '@local/ActivitiesList/services'
import { List, Typography } from '@mui/material'
import { useGetFilteredMedarbetareActivitiesQuery } from '@local/Medarbetare/Services/medarbetareActivitiesList.service'
import { getAreaOfInterestFacetsFromActivity } from '@local/ActivitiesList/Components/Filtering/Utils/filterHelpers'
import { useFeatureFlag } from '@local/Utils/Hooks/useFeatureFlag/useFeatureFlag'
import { useTrackCustomClick } from '@trr/gtm-tracking'

export const AreasOfInterestFilterList = () => {
  const {
    searchParams: {
      areasOfInterest: pickedAreasOfInterest,
      cities,
      isOnline,
      targetGroups,
      activityForm,
    },
    setSearchParamField,
  } = useSearchQueryParams()

  const shouldTrack = useFeatureFlag()('gtm.enabled.aktivitetsbokningar')
  const trackClick = useTrackCustomClick()
  const { isMedarbetare, isCustomer } = useUserContext()
  const searchParams = {
    cities,
    targetGroups,
    isOnline: isOnline ? isOnline : undefined,
    activityForm,
  }

  const { data: publicAreasOfInterest } = useGetFilteredActivitiesQuery(
    { searchParams, isCustomer: isCustomer },
    { skip: isMedarbetare }
  )
  const { data: medarbetareAreasOfInterest } =
    useGetFilteredMedarbetareActivitiesQuery(
      { searchParams, isCustomer: isCustomer },
      {
        skip: !isMedarbetare,
      }
    )

  const activities = isMedarbetare
    ? medarbetareAreasOfInterest
    : publicAreasOfInterest
  const areasOfInterest = getAreaOfInterestFacetsFromActivity(activities)

  const handleSetCategory = (category?: string) => {
    if (shouldTrack && !pickedAreasOfInterest.includes(category)) {
      trackClick('Filtrering', { label: `Kategori: ${category}` })
    }
    setSearchParamField(category, 'areasOfInterest')
  }

  if (!areasOfInterest || areasOfInterest.length === 0) {
    return <Typography ml={1.5}>Inga kategorier kunde hittas</Typography>
  }

  return (
    <List data-testid="listFilterAreasOfInterestList" disablePadding>
      {areasOfInterest.map((area) => (
        <FilterListItem
          title={area.name}
          key={area.name}
          amount={area.count}
          active={pickedAreasOfInterest.includes(area.name)}
          handleOnClick={() => handleSetCategory(area.name)}
          disabled={
            !pickedAreasOfInterest.includes(area.name as string) &&
            area.count === 0
          }
        />
      ))}
    </List>
  )
}
