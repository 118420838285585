import React from 'react'
import { ActivityForm } from '@local/Activity/services/activity.types'
import { preparationTimeFormatted } from '@local/Utils/Helpers/activity.helpers'
import { getPreparationTime } from '@local/Utils/Helpers/getPreparationTime'
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings'
import { date } from '@trr/frontend-datetime'
import { ListItemText } from '@mui/material'
import {
  GroupsOutlined,
  InsertInvitation,
  WatchLater,
} from '@mui/icons-material'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { ActivityFormIconCardListItem } from '@local/Components/ActivityFormIconCardListItem'

interface CallToActionInformationProps {
  timeInMinutes: number
  lastRegisrationDate: string
  activityForm: ActivityForm
  vacancies: number
}

const CallToActionInformation: React.FunctionComponent<
  CallToActionInformationProps
> = ({ timeInMinutes, lastRegisrationDate, activityForm, vacancies }) => {
  const t = useEpiString()

  const formattedTime: { hours: number; minutes: number } =
    timeInMinutes && preparationTimeFormatted(timeInMinutes)

  const formattedVacancies = vacancies === 1 ? t('vacancy') : t('vacancies')
  const isNotWebbinarium = activityForm !== 'Webbinarium'
  return (
    <>
      {activityForm && <ActivityFormIconCardListItem form={activityForm} />}
      {Boolean(formattedTime) && (
        <CardListItem divider={false}>
          <CardListItemIcon aria-hidden>
            <WatchLater />
          </CardListItemIcon>
          <ListItemText
            primary={getPreparationTime(formattedTime)}
            secondary={t('förberedelsetid')}
          />
        </CardListItem>
      )}
      <CardListItem divider={false}>
        <CardListItemIcon aria-hidden>
          <InsertInvitation />
        </CardListItemIcon>
        <ListItemText
          primary={date.format(lastRegisrationDate)}
          secondary={t('callToActionLastRegistrationDate')}
        />
      </CardListItem>
      {isNotWebbinarium && (
        <CardListItem divider={false}>
          <CardListItemIcon aria-hidden>
            <GroupsOutlined />
          </CardListItemIcon>
          <ListItemText primary={`${vacancies} ${formattedVacancies}`} />
        </CardListItem>
      )}
    </>
  )
}

export default CallToActionInformation
