import { merge } from 'lodash'
import { AktivitetsbokningarContent } from '@local/Types'
import { fallbackContent } from '@local/Utils/Content/fallbackContent'

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export const mergeContentWithFallback = (
  content: DeepPartial<AktivitetsbokningarContent>
) => merge(fallbackContent, content)
