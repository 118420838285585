import { Pages } from '@local/App'
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings'
import React, { useContext } from 'react'
import { Context as AppshellContext } from '@local/Utils/AppShellData/Context'
import { Link, Stack } from '@mui/material'

const SwitchViewLink: React.FunctionComponent = () => {
  const t = useEpiString()
  const { currentKey } = useContext(AppshellContext)

  const recordedActivitiesLinkLabel =
    currentKey === Pages.HittaAktiviteterMedarbetare
      ? t('activitiesList.goToTjanstemanRecordedActivities')
      : t('activitiesList.goToKundRecordedActivities')

  const activitiesLinkLabel =
    currentKey === Pages.HittaAktiviteterMedarbetare
      ? t('activitiesList.switchToKundView')
      : t('activitiesList.switchToTjanstemanView')

  return (
    <Stack marginBottom={5} spacing={2}>
      <Link href={t('recordedActivitiesUrl')}>
        {recordedActivitiesLinkLabel}
      </Link>
      <Link href={t('switchViewUrl')}>{activitiesLinkLabel}</Link>
    </Stack>
  )
}

export default SwitchViewLink
