import React from 'react'
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings'
import { USER_REGISTRATION_STATUS } from '@local/Activity/ActivityStatuses'
import { trimTrailingSlash } from '@local/Utils/Helpers/stringHelper'
import { PreparationTime } from '@local/Activity/Components/Layout/PreparationContent/PreparationTime'
import { getLocation } from '@local/Utils/Helpers/getLocation'
import OnlineMeetingButton from '@local/UserActivities/RegisteredActivities/OnlineMeetingButton'
import {
  IRegisteredActivity,
  IRegisteredActivityNextPart,
} from '@local/UserActivities/services/userActivities.types'
import { TabName } from '@local/Activity/Components/ActivityTablist/ActivityTabList'
import { FromSourceTypes } from '@local/Types'
import { getTimespan } from '@local/Utils/Helpers/getTimespan'
import { date } from '@trr/frontend-datetime'
import { Tags } from '@local/Medarbetare/Services/medarbetareActivity.types'
import { getTagText } from '@local/Utils/Helpers/tagHelper'
import { stringByRole } from '@local/Utils/Helpers/stringByRole'
import { Chip, CardHeader, List, ListItemText } from '@mui/material'
import { FmdGood, InsertInvitation, Monitor } from '@mui/icons-material'
import { ActivityCardWrapper } from '@local/Components/ActivityCardWrapper'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'
import { ActivityFormIconCardListItem } from '@local/Components/ActivityFormIconCardListItem'
import {
  UserActivityCardChipContent,
  UserActivityCardPrimaryContent,
} from '@local/UserActivities/Components/UserActivityCardParts'
import { useFeatureFlag } from '@local/Utils/Hooks/useFeatureFlag/useFeatureFlag'
import { useTrackCustomClick } from '@trr/gtm-tracking'

const RegisteredActivityCard: React.FunctionComponent<IRegisteredActivity> = ({
  id,
  activity: { name, form },
  targetAudience: { locations },
  registration: {
    userRegistration: { status },
  },
  schedule: { nextPart, passedCount, totalCount },
  preparation: { hasPreparation, timeRequiredInMinutes },
  isOnline,
  tags,
}) => {
  const t = useEpiString()
  const isActivityformEnabled = useFeatureFlag()(
    'aktivitetsbokningar.showAktivitetsformFilter'
  )
  const shouldTrack = useFeatureFlag()('gtm.enabled.aktivitetsbokningar')
  const trackClick = useTrackCustomClick()
  const isReserve = status === USER_REGISTRATION_STATUS.Reserve
  const dateStageText = (next: IRegisteredActivityNextPart) => {
    if (!next) {
      return [t('workshopDatePassedText')]
    }

    const dateList = [date.format(nextPart.start), getTimespan(nextPart)]

    totalCount > 1 &&
      dateList.push(
        `${t('stageText')} ${
          passedCount === totalCount ? passedCount : passedCount + 1
        }${t('stageSpanText')}${totalCount}`
      )
    return dateList
  }

  const baseActivityUrl = stringByRole({
    clientString: t('activityPage.klient'),
    customerString: t('activityPage.customer'),
  })

  const baseUrl = `${trimTrailingSlash(baseActivityUrl)}`
  const activityCardUrl = `${baseUrl}/${id}?fromSource=${FromSourceTypes.Registered}#${TabName.Start}`

  const handleActivityClick = () => {
    if (shouldTrack) {
      trackClick('aktivitetskort', {
        label: 'Gå till registrerad aktivitet',
        href: baseUrl,
      })
    }
  }

  return (
    <ActivityCardWrapper
      key={`bokat-tillfalle-${id}`}
      href={!tags?.includes(Tags.CANCELLED) ? activityCardUrl : null}
      onClick={handleActivityClick}
    >
      <CardHeader title={name} />
      <UserActivityCardPrimaryContent>
        <List>
          <CardListItem divider={false} disablePadding>
            <CardListItemIcon aria-hidden>
              <InsertInvitation />
            </CardListItemIcon>
            <ListItemText
              data-testid="registeredActivityDate"
              primary={dateStageText(nextPart)[0]}
              secondary={dateStageText(nextPart)[1] ?? ''}
            />
          </CardListItem>
          {timeRequiredInMinutes > 0 && (
            <PreparationTime time={timeRequiredInMinutes} />
          )}
          {nextPart && (
            <CardListItem divider={false} disablePadding>
              {isOnline ? (
                <>
                  <CardListItemIcon aria-hidden>
                    <Monitor />
                  </CardListItemIcon>
                  <ListItemText
                    data-testid="registeredActivityDate"
                    primary="Online"
                  />
                </>
              ) : (
                <>
                  <CardListItemIcon aria-hidden>
                    <FmdGood />
                  </CardListItemIcon>
                  <ListItemText
                    data-testid="registeredActivityDate"
                    primary={
                      'På plats - ' +
                      getLocation(locations[0], nextPart?.physicalAddress)
                    }
                  />
                </>
              )}
            </CardListItem>
          )}
          {isActivityformEnabled && form && (
            <ActivityFormIconCardListItem form={form} />
          )}
        </List>
      </UserActivityCardPrimaryContent>
      <UserActivityCardChipContent>
        {isReserve && <Chip label={t('isReserveChip')} color="secondary" />}
        {hasPreparation && (
          <Chip label={t('hasPreparationChip')} color="primary" />
        )}
        {tags?.map((tag) => (
          <Chip label={getTagText(tag)} key={tag} color="error" />
        ))}
        {nextPart?.remoteLink?.url && !tags?.includes(Tags.CANCELLED) && (
          <OnlineMeetingButton webLink={nextPart.remoteLink.url} />
        )}
      </UserActivityCardChipContent>
    </ActivityCardWrapper>
  )
}

export default RegisteredActivityCard
