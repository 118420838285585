import React from 'react'
import {
  Button,
  Card,
  CardContent,
  CardProps,
  Typography,
  styled,
} from '@mui/material'

interface CallToActionProps {
  ctaAreaTitle: string | React.JSX.Element
  primaryButtonText?: string
  primaryButtonOnClick?: () => void
  secondaryButtonText?: string
  secondaryButtonOnClick?: () => void
}

const StyledCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: '240px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '340px',
  },
}))

const ButtonWrapper = styled(CardContent)({
  paddingTop: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
})

export const CallToAction = ({
  ctaAreaTitle,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
  children,
  ...props
}: CallToActionProps & CardProps) => (
  <StyledCard {...props}>
    <CardContent>
      <Typography variant="subtitle1" gutterBottom>
        {ctaAreaTitle}
      </Typography>
      {children}
    </CardContent>
    {(primaryButtonText || secondaryButtonText) && (
      <ButtonWrapper>
        {primaryButtonText && (
          <Button
            fullWidth
            onClick={primaryButtonOnClick}
            role="button"
            data-testid="cta-primary-button"
          >
            {primaryButtonText}
          </Button>
        )}
        {secondaryButtonText && (
          <Button
            fullWidth
            variant="outlined"
            onClick={secondaryButtonOnClick}
            role="button"
            data-testid="cta-secondary-button"
          >
            {secondaryButtonText}
          </Button>
        )}
      </ButtonWrapper>
    )}
  </StyledCard>
)
