/* eslint-disable react/jsx-no-bind */
import React from 'react'
import useCtaStatuses from '@local/Utils/Hooks/useCtaStatuses'
import {
  LIFECYCLE_STATUS,
  REGISTRATION_AVAILABILITY,
} from '@local/Activity/ActivityStatuses'
import CallToActionParts from '@local/Activity/Components/ActivityCallToAction/Components/CallToActionParts/CallToActionParts'
import CallToActionHasStarted from '@local/Activity/Components/ActivityCallToAction/Components/CallToActionHasStarted/CallToActionHasStarted'
import CallToActionHasPassed from '@local/Activity/Components/ActivityCallToAction/Components/CallToActionHasPassed/CallToActionHasPassed'
import CallToActionInformation from '@local/Activity/Components/ActivityCallToAction/Components/CallToActionInformation/CallToActionInformation'
import {
  IMedarbetareActivityResponse,
  Tags,
} from '@local/Medarbetare/Services/medarbetareActivity.types'
import { filterTags, getTagColor } from '@local/Utils/Helpers/tagHelper'
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import { pushFeedback } from '@local/Components/SnackNotification/SnackNotification'
import { useFeatureFlag } from '@local/Utils/Hooks/useFeatureFlag/useFeatureFlag'
import CallToActionOccasions from '@local/Activity/Components/ActivityCallToAction/Components/CallToActionOccasions/CallToActionOccasions'
import { copyFormattedUrl } from '@local/Utils/Helpers/copyFormattedUrl'
import { TabName } from '@local/Activity/Components/ActivityTablist/ActivityTabList'
import {
  Box,
  Chip,
  List,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { CallToAction } from '@local/Activity/Components/CallToAction/CallToAction'
import {
  generateUtmDate,
  generateUtmParams,
} from '@local/Utils/Helpers/urchinTrackingModuleHelper'

interface IActivityCallToAction {
  activity: IMedarbetareActivityResponse
}

export const MedarbetareActivityCallToAction: React.FunctionComponent<
  IActivityCallToAction
> = ({ activity }) => {
  const t = useEpiString()
  const { isCustomer } = useUserContext()
  const {
    registration: {
      registrationAvailability,
      vacancies,
      hasVacancies,
      hasReserveVacancies,
      lastRegistrationDate,
    },
    schedule,
    isOnline,
    preparation,
    activity: { name, form },
    targetAudience: { targetGroup },
    tags,
  } = activity
  const hasStarted =
    schedule.lifecycleStatus === LIFECYCLE_STATUS.ONGOING &&
    registrationAvailability !==
      REGISTRATION_AVAILABILITY.NO_REGISTRATION_REQUIRED

  const {
    ctaAreaRoundCanUnRegister,
    ctaAreaRoundHasStarted,
    ctaAreaRoundHasPassed,
  } = useCtaStatuses({
    lifecycleStatus: schedule.lifecycleStatus,
    registrationAvailability,
    isCustomerUrl: false,
    schedule,
    activityId: '',
    activityName: name,
    hasVacancies,
    hasReserveVacancies,
    targetGroup,
  })

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const chipsToHide: Tags[] = isCustomer ? [Tags.REQUIRES_ADVISOR_APPROVAL] : []

  const disableCopyLink = useFeatureFlag()(
    'aktivitetsbokningar.medarbetare.disableCopyLink'
  )

  if (schedule.parts?.length === 0) {
    return null
  }

  const isCancelled = tags.includes(Tags.CANCELLED)

  const ctaAreaTitle = () =>
    tags.length === 0 ? (
      ''
    ) : (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {filterTags(tags, chipsToHide).map((tag) => (
          <Chip label={t(`tags.${tag}`)} key={tag} color={getTagColor(tag)} />
        ))}
      </Box>
    )

  const handleCopyLink = async () => {
    const utmTagParams = generateUtmParams({
      utm_source: t('utmSource'),
      utm_medium: t('utmMedium'),
      utm_campaign: t('utmCampaign'),
      utm_content: generateUtmDate(new Date()),
    })
    await copyFormattedUrl(
      `TRR: ${activity.activity.name}`,
      `${window.location.origin}${t('activityUrl')}${
        activity.id
      }?${utmTagParams}#${TabName.Start}`
    ).then(() => {
      pushFeedback(t('toaster.linkCopied'), 'success', isDesktop ? 40 : 0)
    })
  }

  return (
    <>
      <CallToAction
        ctaAreaTitle={ctaAreaTitle()}
        primaryButtonOnClick={
          isCancelled || disableCopyLink ? undefined : handleCopyLink
        }
        primaryButtonText={isCancelled ? undefined : t('primaryButtonText')}
        data-testid="ctaArea"
      >
        {activity && (
          <List>
            <CallToActionOccasions activity={activity} />

            <CallToActionParts
              parts={schedule.parts}
              canUnregister={ctaAreaRoundCanUnRegister}
              hasStarted={ctaAreaRoundHasStarted}
              isOnline={isOnline}
            />

            <CallToActionInformation
              timeInMinutes={preparation.timeRequiredInMinutes}
              lastRegisrationDate={
                lastRegistrationDate || activity.schedule.start
              }
              activityForm={form}
              vacancies={vacancies}
            />

            {hasStarted && <CallToActionHasStarted />}
          </List>
        )}

        {ctaAreaRoundHasPassed && (
          <CallToActionHasPassed isOnline={isOnline} parts={schedule.parts} />
        )}

        {!isCancelled && (
          <Typography variant="body1">
            {tags.includes(Tags.REQUIRES_ADVISOR_APPROVAL)
              ? t('shareLinkText.bokasViaTrr')
              : t('shareLinkText')}
          </Typography>
        )}
      </CallToAction>
    </>
  )
}
