import React, { useEffect, useRef } from 'react'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import ActivityCardList from '@local/ActivitiesList/Components/ActivityCardList/ActivityCardList'
import { useGetFilteredMedarbetareActivitiesQuery } from '@local/Medarbetare/Services/medarbetareActivitiesList.service'
import SwitchViewLink from '@local/Medarbetare/MedarbetareActivitiesList/components/SwitchViewLink/SwitchViewLink'
import {
  filterTabs,
  useSearchQueryParams,
} from '@local/Utils/Hooks/useSearchQueryParams'
import { Filters } from '@local/ActivitiesList/Components/Filtering/Filters'
import { useFeatureFlag } from '@local/Utils/Hooks/useFeatureFlag/useFeatureFlag'
import { Stack } from '@mui/material'

export const MedarbetareActivitiesList: React.FunctionComponent = () => {
  const wrapperRef = useRef(null)
  const [initialyLoaded, setInitialyLoaded] = React.useState(false)
  const { isCustomer } = useUserContext()
  const isActivityformEnabled = useFeatureFlag()(
    'aktivitetsbokningar.showAktivitetsformFilter'
  )
  const {
    searchParams: {
      tab,
      areasOfInterest,
      targetGroups,
      activityForm,
      cities,
      isOnline,
      page,
    },
  } = useSearchQueryParams()

  const activitySearchParams = {
    areasOfInterest,
    activityForm,
    cities,
    targetGroups,
    isOnline: isOnline ? isOnline : undefined,
    page,
  }

  useEffect(() => {
    initialyLoaded
      ? wrapperRef?.current?.scrollIntoView({ behavior: 'smooth' })
      : setInitialyLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const { data: activities, isFetching } =
    useGetFilteredMedarbetareActivitiesQuery(
      { searchParams: activitySearchParams, isCustomer },
      {
        skip: tab === filterTabs.Recorded,
      }
    )

  return (
    <Stack ref={wrapperRef}>
      <SwitchViewLink />
      <Filters
        showLocationFilter
        showTargetGroupFilter={isCustomer}
        showAreasOfInterestFilter={!isCustomer}
        showActivityformFilter={isActivityformEnabled}
      />
      <ActivityCardList activities={activities} isLoading={isFetching} />
    </Stack>
  )
}
