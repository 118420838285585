import React from 'react'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import EpiParser from '@local/Activity/Components/Layout/EpiParser'

export const ClosingStatement = () => {
  const { closingStatement } = usePickEpiContent()

  if (!closingStatement) {
    return
  }

  return <EpiParser body={closingStatement.mainBody} />
}
