import {
  IMailAnsokanFormValues,
  MailAnsokanFormSchema,
  mapAnsokanToArbetsplatsFormValues,
} from '@local/Activity/Components/MailAnsokan/MailAnsokanForm/MailAnsokanForm.schema'
import { IMailAnsokanFormProps } from '@local/Activity/Components/MailAnsokan/MailAnsokanTypes'
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings'
import {
  FormikProps,
  FormikTouched,
  setNestedObjectValues,
  withFormik,
} from 'formik'
import { isEmpty } from 'ramda'
import React from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link as MuiLink,
  Stack,
  TextField,
  styled,
} from '@mui/material'
import { Warning } from '@mui/icons-material'

const CustomTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const MailAnsokanForm: React.FC<
  IMailAnsokanFormProps & FormikProps<IMailAnsokanFormValues>
> = (props) => {
  const {
    children,
    handleBlur,
    handleChange,
    submitForm,
    validateForm,
    setTouched,
    touched,
    errors,
    errorOccurred,
  } = props

  const t = useEpiString()

  const handleSubmit = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          setTouched(
            setNestedObjectValues<FormikTouched<IMailAnsokanFormValues>>(
              validationErrors,
              true
            )
          )
        } else {
          submitForm()
        }
      })

      .catch((e) => console.error(e))
  }

  const generateErrorMessage = (fieldName: keyof IMailAnsokanFormValues) =>
    shouldShowErrorMessage(fieldName) ? errors[fieldName] : ''

  const shouldShowErrorMessage = (
    fieldName: keyof IMailAnsokanFormValues
  ): boolean =>
    touched[fieldName] !== undefined && errors[fieldName] !== undefined

  return (
    <FormControl fullWidth>
      <Stack>
        <Stack mb={4}>{children}</Stack>
        <CustomTextField
          fullWidth
          label={t('formAnsokanFirstname')}
          onChange={handleChange}
          name="firstName"
          helperText={generateErrorMessage('firstName')}
          error={shouldShowErrorMessage('firstName')}
          onBlur={handleBlur}
        />
        <CustomTextField
          fullWidth
          label={t('formAnsokanLastname')}
          name="lastName"
          onChange={handleChange}
          helperText={generateErrorMessage('lastName')}
          error={shouldShowErrorMessage('lastName')}
          onBlur={handleBlur}
        />
        <CustomTextField
          fullWidth
          label={t('formAnsokanEmail')}
          name="email"
          onChange={handleChange}
          helperText={generateErrorMessage('email')}
          error={shouldShowErrorMessage('email')}
          onBlur={handleBlur}
        />
        <CustomTextField
          fullWidth
          label={t('formAnsokanRole')}
          name="role"
          helperText={generateErrorMessage('role')}
          error={shouldShowErrorMessage('role')}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <CustomTextField
          fullWidth
          label={t('formAnsokanMobilenumber')}
          name="phoneNumber"
          helperText={generateErrorMessage('phoneNumber')}
          error={shouldShowErrorMessage('phoneNumber')}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <CustomTextField
          fullWidth
          label={t('formAnsokanCompany')}
          name="organizationName"
          helperText={generateErrorMessage('organizationName')}
          error={shouldShowErrorMessage('organizationName')}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <CustomTextField
          fullWidth
          label={t('formAnsokanOrganizationnumber')}
          name="organizationNumber"
          helperText={generateErrorMessage('organizationNumber')}
          error={shouldShowErrorMessage('organizationNumber')}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <CustomTextField
          fullWidth
          label={t('formAnsokanLocation')}
          name="location"
          helperText={generateErrorMessage('location')}
          error={shouldShowErrorMessage('location')}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <CustomTextField
          fullWidth
          label={t('formAnsokanNotes')}
          multiline
          name="notes"
          helperText={generateErrorMessage('notes')}
          error={shouldShowErrorMessage('notes')}
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={{
            sx: {
              height: (theme) => theme.spacing(15),
              alignItems: 'baseline',
            },
          }}
        />
        <FormControlLabel
          control={<Checkbox name="approved" onChange={handleChange} />}
          label={t('formAnsokanConfirmationText')}
        />
        {shouldShowErrorMessage('approved') && (
          <FormHelperText error>
            {generateErrorMessage('approved')}
          </FormHelperText>
        )}
        <MuiLink href={t('formAnsokanPrivacyLinkUrl')}>
          {t('formAnsokanPrivacyLinkText')}
        </MuiLink>

        <Button
          onClick={handleSubmit}
          data-testid="primaryselector"
          sx={{ mt: (theme) => theme.spacing(4) }}
        >
          {t('formAnsokanSubmitButtonText')}
        </Button>
        {errorOccurred && (
          <>
            <FormHelperText error>
              {t('formAnsokanErrorUnknown')}
            </FormHelperText>
            <Warning color="error" />
          </>
        )}
      </Stack>
    </FormControl>
  )
}

const SkapaUppdragFormik = withFormik<
  IMailAnsokanFormProps,
  IMailAnsokanFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  isInitialValid: () => false,
  mapPropsToValues: ({ ansokan }) => mapAnsokanToArbetsplatsFormValues(ansokan),
  handleSubmit: (values, { setSubmitting, props: { handleSubmit } }) => {
    setSubmitting(true)

    handleSubmit(values)
  },
  validationSchema: () => MailAnsokanFormSchema(),
  displayName: 'mailAnsokanForm',
})(MailAnsokanForm)

export default SkapaUppdragFormik
