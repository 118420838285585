import React from 'react'
import { FilterListItem } from '@local/ActivitiesList/Components/Filtering/Components/FilterListItem'
import { List, Typography } from '@mui/material'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import { useGetFilteredMedarbetareActivitiesQuery } from '@local/Medarbetare/Services/medarbetareActivitiesList.service'
import { useGetFilteredActivitiesQuery } from '@local/ActivitiesList/services'
import {
  getCityFacetsFromActivity,
  getOnlineFacetsFromActivity,
} from '@local/ActivitiesList/Components/Filtering/Utils/filterHelpers'
import { capitalize } from '@local/Utils/Helpers/stringHelper'
import FilterListSubheader from '@local/ActivitiesList/Components/Filtering/Components/FilterListSubheader'
import { useFeatureFlag } from '@local/Utils/Hooks/useFeatureFlag/useFeatureFlag'
import { useTrackCustomClick } from '@trr/gtm-tracking'

export const LocationFilterList = () => {
  const {
    searchParams: {
      cities: pickedCities,
      isOnline,
      areasOfInterest,
      targetGroups,
      activityForm,
    },
    setSearchParamField,
  } = useSearchQueryParams()
  const shouldTrack = useFeatureFlag()('gtm.enabled.aktivitetsbokningar')
  const trackClick = useTrackCustomClick()
  const { isMedarbetare, isCustomer } = useUserContext()
  const searchParams = {
    areasOfInterest,
    targetGroups,
    activityForm,
  }
  const { data: medarbetareActivities } =
    useGetFilteredMedarbetareActivitiesQuery(
      { searchParams, isCustomer: isCustomer },
      { skip: !isMedarbetare }
    )
  const { data: publicActivities } = useGetFilteredActivitiesQuery(
    { searchParams, isCustomer: isCustomer },
    { skip: isMedarbetare }
  )

  const activities = isMedarbetare ? medarbetareActivities : publicActivities
  const online = getOnlineFacetsFromActivity(activities)
  const cities = getCityFacetsFromActivity(activities)
  const onlineCount = online?.find((x) => x.name === 'True')?.count ?? 0
  const citiesToSkip = ['Hela Sverige', 'Online']
  const physicalLocations = cities?.filter(
    (location) => !citiesToSkip.includes(location.name)
  )

  const handleSetLocation = (location?: string) => {
    if (shouldTrack && pickedCities?.length === 0) {
      trackClick('Filtrering', { label: 'Plats: Fysisk' })
    }
    setSearchParamField(location, 'cities')
  }
  const handleSetIsOnline = (isOnline: boolean) => {
    if (shouldTrack && isOnline === true) {
      trackClick('Filtrering', { label: 'Plats: Online' })
    }
    setSearchParamField(isOnline?.toString(), 'isOnline')
  }

  return (
    <List data-testid="listFilterLocationList" disablePadding>
      <FilterListItem
        key="Online"
        title="Online"
        amount={onlineCount}
        handleOnClick={() => handleSetIsOnline(!isOnline)}
        active={isOnline}
        disabled={onlineCount === 0}
      />
      {physicalLocations?.length > 0 && (
        <>
          <FilterListSubheader>
            <Typography variant="subtitle2">{'På plats i:'}</Typography>
          </FilterListSubheader>
          {physicalLocations?.map((location) => (
            <FilterListItem
              key={location.name}
              title={capitalize(location.name)}
              amount={location.count}
              handleOnClick={() => handleSetLocation(location.name)}
              active={pickedCities.includes(location.name)}
              disabled={
                !pickedCities.includes(location.name) && location.count === 0
              }
            />
          ))}
        </>
      )}
    </List>
  )
}
