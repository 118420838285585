import { useContext } from 'react'
import { IAppShellConfiguration } from '@local/App.types'

import { Context } from './Context'

export const useAppShellData = <TResult = unknown>(
  selector: (data: IAppShellConfiguration) => TResult
): TResult => {
  const data = useContext(Context)
  return selector(data)
}
