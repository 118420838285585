import React from 'react'
import { ActivityForm } from '@local/Activity/services/activity.types'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'
import { AutoStories } from '@mui/icons-material'
import { ListItemText } from '@mui/material'

interface ActivityFormIconCardListItemProps {
  form: ActivityForm
}

export const ActivityFormIconCardListItem = ({
  form,
}: ActivityFormIconCardListItemProps) => (
  <CardListItem divider={false}>
    <CardListItemIcon aria-hidden>
      <AutoStories />
    </CardListItemIcon>
    <ListItemText primary={form} />
  </CardListItem>
)
