import React from 'react'
import { getPreparationTime } from '@local/Utils/Helpers/getPreparationTime'
import { Assignment } from '@mui/icons-material'
import { ListItemText } from '@mui/material'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'

interface IPreparationTime {
  time: number
}

export const PreparationTime = ({ time }: IPreparationTime): JSX.Element => {
  const formattedTime: { hours: number; minutes: number } = time && {
    hours: Math.floor(time / 60),
    minutes: time % 60,
  }

  return (
    <CardListItem divider={false}>
      <CardListItemIcon aria-hidden>
        <Assignment />
      </CardListItemIcon>
      <ListItemText
        data-testid="registeredActivityDate"
        primary={getPreparationTime(formattedTime)}
        secondary={'förberedelsetid'}
      />
    </CardListItem>
  )
}
