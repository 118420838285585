import React from 'react'
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings'
import { ListItemText, Typography } from '@mui/material'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'
import { Info } from '@mui/icons-material'

const CallToActionHasStarted: React.FunctionComponent = () => {
  const t = useEpiString()

  return (
    <CardListItem>
      <CardListItemIcon aria-hidden>
        <Info />
      </CardListItemIcon>
      <ListItemText>
        <Typography variant={'subtitle2'}>
          {t('occasionHasStartedInfo')}
        </Typography>
      </ListItemText>
    </CardListItem>
  )
}

export default CallToActionHasStarted
