import {
  Card,
  CardActionArea,
  CardProps,
  CardContent,
  CardHeader,
  List,
  styled,
} from '@mui/material'
import React, { PropsWithChildren } from 'react'

type SizeOptions = 'small' | 'medium'

interface ActivityCardProps {
  size?: SizeOptions
  href?: string
  onClick?: () => void
}

const getMinHeight = (size: keyof SizeOptions | SizeOptions) => {
  switch (size) {
    case 'small':
      return '15rem'
    case 'medium':
      return '25rem'
  }
}

export const ActivityCardWrapper = ({
  size = 'medium',
  href,
  onClick,
  children,
  ...props
}: ActivityCardProps & PropsWithChildren & CardProps) => (
  <Card sx={{ height: '100%', borderRadius: '8px' }} {...props}>
    <CardActionArea
      href={href}
      onClick={onClick}
      data-trackable="false"
      sx={{
        minHeight: getMinHeight(size),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        justifyContent: 'start',
      }}
    >
      {children}
    </CardActionArea>
  </Card>
)

export const ActivityCardContent = styled(CardContent)({
  paddingBottom: '1rem',
  paddingTop: 0,
})

export const ActivityCardHeader = styled(CardHeader)({
  paddingTop: '1rem',
  fontSize: '26px',
  wordBreak: 'break-word',
  hyphens: 'auto',
})

export const ActivityList = styled(List)({
  padding: 0,
})
