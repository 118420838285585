import React, { useEffect } from 'react'
import { isNil } from 'ramda'
import useToggle from '@local/Utils/Hooks/useToggle'
import { lockScrollbar } from '@local/Utils/Helpers/lockScrollBar'
import { parseYoutubeDuration } from '@local/Utils/Helpers/parseYoutubeDuration/parseYoutubeDuration'
import { date } from '@trr/frontend-datetime'
import VideoModal from '@local/ActivitiesList/Components/VideoModal'
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings'
import {
  CardMedia,
  Grid,
  ListItemText,
  Typography,
  styled,
} from '@mui/material'
import { AccessTime, InsertInvitation } from '@mui/icons-material'
import { ActivityGrid } from '@local/Components/ActivityGrid'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'
import {
  ActivityCardContent,
  ActivityCardHeader,
  ActivityCardWrapper,
  ActivityList,
} from '@local/Components/ActivityCardWrapper'
import { Thumbnails } from '@local/WebinarsList/services/webinar.types'
import EmptyResult from '@local/Components/EmptyResult/EmptyResult'
import { useFeatureFlag } from '@local/Utils/Hooks/useFeatureFlag/useFeatureFlag'
import { useTrackCustomClick } from '@trr/gtm-tracking'

const EmptyWrapper = styled('div')({
  width: '100%',
  paddingTop: '4rem',
  paddingBottom: '2rem',
})

export interface IVideo {
  broadcastDate: string
  duration: string
  heading: string
  body: string
  id: string
  thumbnails?: Thumbnails
  videoId?: string
  videoUrl?: string
}

interface IVideoGrid {
  webinarVideos: IVideo[]
}

export const VideoGrid: React.FunctionComponent<IVideoGrid> = ({
  webinarVideos,
}) => {
  const t = useEpiString()
  const { toggle, handleToggle } = useToggle()
  const [selectedWebinar, setSelectedWebinar] = React.useState<IVideo>(null)
  const shouldTrack = useFeatureFlag()('gtm.enabled.aktivitetsbokningar')
  const trackClick = useTrackCustomClick()
  const {
    searchParams: { openVideo },
    setSearchParamField,
  } = useSearchQueryParams()

  const setPlay = (video: IVideo) => {
    setSelectedWebinar(video)
    handleToggle()
    lockScrollbar(true)
  }

  const handleClick = (video: IVideo) => () => {
    if (shouldTrack) {
      trackClick('button_click', {
        label: 'Öppna videomodal',
      })
    }
    setPlay(video)
  }

  const handleKeyDown = (video: IVideo) => (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setPlay(video)
    }
  }

  useEffect(() => {
    if (openVideo) {
      const video = Object.values(webinarVideos)?.find(
        (webinar) => webinar.id === openVideo
      )
      video && setPlay(video)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => () => lockScrollbar(false), [])

  const onClose = () => {
    handleToggle()
    lockScrollbar(false)
    setSearchParamField(null, 'openVideo')
  }

  if (isNil(webinarVideos)) {
    return (
      <EmptyWrapper>
        <EmptyResult
          heading={t('customerVideoEmptyHeading')}
          description={t('customerVideoEmptyDescription')}
        />
      </EmptyWrapper>
    )
  }
  return (
    <ActivityGrid data-testid="activityCardWrapper">
      {!isNil(webinarVideos) &&
        Object.values(webinarVideos).map((video: IVideo, key: number) => {
          const hasMetaData = video?.broadcastDate || video?.duration
          const videoThumbnail =
            video?.thumbnails &&
            Object.values(video.thumbnails)?.sort((v) => v?.height)[
              Object.values(video.thumbnails)?.length - 1
            ]
          return (
            <Grid item xs={1} sm={1} md={1} key={key}>
              <ActivityCardWrapper
                data-testid={'video-grid-item'}
                onClick={handleClick(video)}
                onKeyDown={handleKeyDown(video)}
                tabIndex={0}
              >
                {videoThumbnail?.url && (
                  <CardMedia
                    component="img"
                    image={videoThumbnail?.url}
                    alt={video?.heading}
                  />
                )}
                <ActivityCardHeader title={video?.heading} />
                {hasMetaData && (
                  <ActivityCardContent>
                    <ActivityList>
                      {video?.broadcastDate && (
                        <CardListItem divider={false}>
                          <CardListItemIcon aria-hidden>
                            <InsertInvitation />
                          </CardListItemIcon>
                          <ListItemText
                            primary={date.format(video.broadcastDate, {
                              style: 'numeric',
                            })}
                          />
                        </CardListItem>
                      )}
                      {video?.duration && (
                        <CardListItem divider={false}>
                          <CardListItemIcon aria-hidden>
                            <AccessTime />
                          </CardListItemIcon>
                          <ListItemText
                            primary={parseYoutubeDuration(video.duration)}
                          />
                        </CardListItem>
                      )}
                    </ActivityList>
                  </ActivityCardContent>
                )}
                {video?.body && (
                  <ActivityCardContent>
                    <Typography variant="subtitle1">{video.body}</Typography>
                  </ActivityCardContent>
                )}
              </ActivityCardWrapper>
            </Grid>
          )
        })}
      {!isNil(selectedWebinar) && (
        <VideoModal
          toggled={toggle}
          handleClose={onClose}
          videoId={selectedWebinar.id}
          heading={selectedWebinar.heading}
          body={selectedWebinar.body}
          broadcastDate={selectedWebinar.broadcastDate}
        />
      )}
    </ActivityGrid>
  )
}
