import React from 'react'
import { time, date } from '@trr/frontend-datetime'
import { Pages } from '@local/App'
import { trimTrailingSlash } from '@local/Utils/Helpers/stringHelper'
import { useLocation } from 'react-router-dom'
import { IFilteredActivityListItem } from '@local/ActivitiesList/services/activitiesList.types'
import { buildImageUrl } from '@local/Utils/Helpers/ImageBank/imageHelpers'
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings'
import { useUserContext } from '@local/Utils/Hooks/useUserContext'
import { TabName } from '@local/Activity/Components/ActivityTablist/ActivityTabList'
import { Tags } from '@local/Medarbetare/Services/medarbetareActivity.types'
import { filterTags, getTagColor } from '@local/Utils/Helpers/tagHelper'
import { IFilteredMedarbetareActivityListItem } from '@local/Medarbetare/Services/medarbetareActivitiesList.types'
import { Typography, Chip, ListItemText, Stack, CardMedia } from '@mui/material'
import {
  FmdGood,
  InsertInvitation,
  MonitorOutlined,
  Person,
} from '@mui/icons-material'
import { CardListItem } from '@local/Components/CardListItem/CardListItem'
import { CardListItemIcon } from '@local/Components/CardListItem/CardListItemIcon'
import {
  ActivityCardWrapper,
  ActivityCardContent,
  ActivityCardHeader,
  ActivityList,
} from '@local/Components/ActivityCardWrapper'
import { ActivityFormIconCardListItem } from '@local/Components/ActivityFormIconCardListItem'
import { useFeatureFlag } from '@local/Utils/Hooks/useFeatureFlag/useFeatureFlag'
import { useTrackCustomClick } from '@trr/gtm-tracking'

type CombinedActivityListItem = IFilteredActivityListItem &
  IFilteredMedarbetareActivityListItem

export interface ActivityCardProps extends Partial<CombinedActivityListItem> {
  layout: 'standing' | 'laying'
}

const ActivityCard = ({
  nextActivityRoundId,
  isOnline,
  activityName,
  activityForm,
  nextActivityRoundStart,
  preamble,
  imageUrl,
  nrOfUpcomingActivityRounds,
  hasVacancies,
  hasReserveVacancies,
  nextActivityRoundTargetGroup,
  tags,
  cities,
}: ActivityCardProps): JSX.Element => {
  const { search, pathname } = useLocation()
  const t = useEpiString()
  const shouldTrack = useFeatureFlag()('gtm.enabled.aktivitetsbokningar')
  const { isCustomer } = useUserContext()
  const { isMedarbetare } = useUserContext()
  const trackClick = useTrackCustomClick()
  const dateAndTime = (start: string): string =>
    `${date.format(start)} | kl ${time.format(start)}`

  const chipsToHide: Tags[] = isCustomer ? [Tags.REQUIRES_ADVISOR_APPROVAL] : []

  if (!pathname) {
    return null
  }

  const baseUrl = `${trimTrailingSlash(pathname)}/${
    isMedarbetare ? Pages.AktivitetMedarbetare : Pages.Aktivitet
  }`

  const handleActivityClick = () => {
    if (shouldTrack) {
      trackClick('aktivitetskort', {
        label: 'Gå till aktivitet',
        href: baseUrl,
      })
    }
  }

  return (
    <ActivityCardWrapper
      onClick={handleActivityClick}
      href={`${baseUrl}/${nextActivityRoundId}${search ? search : ''}#${
        TabName.Start
      }`}
      data-testid="activity-card"
    >
      <CardMedia
        component="img"
        alt="Aktivitetens bild"
        image={buildImageUrl(imageUrl)}
      />
      <ActivityCardHeader title={activityName} />
      <ActivityCardContent>
        <ActivityList>
          <CardListItem divider={false}>
            <CardListItemIcon aria-hidden>
              <InsertInvitation />
            </CardListItemIcon>
            <ListItemText
              primary={dateAndTime(nextActivityRoundStart)}
              secondary={
                nrOfUpcomingActivityRounds > 1
                  ? t('activityHasMoreOccasions')
                  : undefined
              }
            />
          </CardListItem>
          {isOnline ? (
            <CardListItem divider={false}>
              <CardListItemIcon aria-hidden>
                <MonitorOutlined />
              </CardListItemIcon>
              <ListItemText primary="Online" />
            </CardListItem>
          ) : (
            cities?.length > 0 && (
              <CardListItem divider={false}>
                <CardListItemIcon aria-hidden>
                  <FmdGood />
                </CardListItemIcon>
                <ListItemText
                  primary={
                    cities.length > 1
                      ? 'På plats - flera platser'
                      : `På plats ${cities[0] ? ' - ' + cities[0] : ''}`
                  }
                />
              </CardListItem>
            )
          )}
          {isCustomer && nextActivityRoundTargetGroup && (
            <CardListItem divider={false}>
              <CardListItemIcon aria-hidden>
                <Person />
              </CardListItemIcon>
              <ListItemText primary={`För ${nextActivityRoundTargetGroup}`} />
            </CardListItem>
          )}
          {activityForm && <ActivityFormIconCardListItem form={activityForm} />}
        </ActivityList>
      </ActivityCardContent>
      {!isMedarbetare && (
        <>
          {!hasVacancies && !hasReserveVacancies && (
            <ActivityCardContent>
              <CardListItem divider={false}>
                <Chip label="Fullbokad" color="error" />
              </CardListItem>
            </ActivityCardContent>
          )}
          <ActivityCardContent>
            <Typography
              gutterBottom={false}
              variant="subtitle1"
              fontWeight="medium"
            >
              {preamble}
            </Typography>
          </ActivityCardContent>
        </>
      )}
      {isMedarbetare && tags.length > 0 && (
        <ActivityCardContent>
          <Stack direction="row" rowGap={1} flexWrap={'wrap'} gap={1}>
            {filterTags(tags, chipsToHide).map((tag: Tags) => (
              <Chip
                key={tag}
                label={t(`tags.${tag}`)}
                color={getTagColor(tag)}
              />
            ))}
          </Stack>
        </ActivityCardContent>
      )}
    </ActivityCardWrapper>
  )
}

export default ActivityCard
