import { AktivitetsbokningarContent } from '@local/Types'

export const fallbackContent: AktivitetsbokningarContent = {
  activity: {},
  activityBaseDescription: {},
  activityList: {},
  registered: {},
  attended: {},
  closingStatement: {
    mainBody: 'closingStatement.mainBody',
  },
  recorded: {
    heading: 'recorded.heading',
  },
  bonzunCard: {
    heading: 'bonzunCard.heading',
    body: 'bonzunCard.body',
    actionText: 'bonzunCard.actionText',
    actionUrl: 'bonzunCard.actionUrl',
  },
  educationArticleCardPuff: {
    heading: 'educationArticleCardPuff.heading',
    body: 'educationArticleCardPuff.body',
    actionText: 'educationArticleCardPuff.actionText',
    actionUrl: 'educationArticleCardPuff.actionUrl',
  },
  recordedPuffCard: {
    heading: 'recordedPuffCard.heading',
    body: 'recordedPuffCard.body',
    actionText: 'recordedPuffCard.actionText',
    actionUrl: 'recordedPuffCard.actionUrl',
  },
  trrActivityPuffCard: {
    heading: 'trrActivityPuffCard.heading',
    body: 'trrActivityPuffCard.body',
    actionText: '',
    actionUrl: '',
  },
}
