import React from 'react'
import { OptionsObject, enqueueSnackbar, closeSnackbar } from 'notistack'
import { Alert, AlertColor, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

export const pushFeedback = (
  body: string,
  severity: AlertColor = 'success',
  topDistanceInPixels: number
) => {
  const content = (
    <Alert
      severity={severity}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => closeSnackbar()}
        >
          <Close fontSize="inherit" />
        </IconButton>
      }
    >
      {body}
    </Alert>
  )
  const snackbarSettings: OptionsObject = {
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    SnackbarProps: { style: { top: `${topDistanceInPixels}px` } },
    preventDuplicate: true,
  }

  enqueueSnackbar({
    content,
    ...snackbarSettings,
  })
}
