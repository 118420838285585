import React from 'react'
import { BoxProps, Box, Typography, Stack, styled, Button } from '@mui/material'

import EmptyIllustration from './EmptyIllustration'

type EmptyResultAction = {
  label: string
} & (
  | {
      onClick: () => void
    }
  | {
      link: string
    }
)

interface IEmptyResultProps extends BoxProps {
  heading: string
  description: string
  action?: EmptyResultAction
}

const IllustrationWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '386px',
  padding: theme.spacing(0, 5),
}))

const EmptyResult: React.FC<IEmptyResultProps> = ({
  heading,
  description,
  action,
  ...boxProps
}) => (
  <Box {...boxProps}>
    <Stack
      spacing={3}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <IllustrationWrapper>
        <EmptyIllustration />
      </IllustrationWrapper>
      <Typography variant="h3" gutterBottom>
        {heading}
      </Typography>
      <Typography variant="body1">{description}</Typography>
      {action && (
        <Button
          variant="contained"
          color="primary"
          onClick={'onClick' in action ? action.onClick : undefined}
          href={'link' in action ? action.link : undefined}
        >
          {action.label}
        </Button>
      )}
    </Stack>
  </Box>
)

export default EmptyResult
